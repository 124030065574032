/* :root {
  --error: #e7195a;
} */

.App {
  max-width: 1000px;
  margin: 0 auto;
}

body {
  background-color: rgb(243, 238, 238);
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 20px;
}

select {
  padding: 9px 10px;
}

/* input.error,
textarea.error {
  border: 2px solid var(--error);
} */

@media screen and (max-width: 900px) {
  body {
    margin: 10px 20px;
  }

  h1,
  h2 {
    text-align: center;
  }
}
