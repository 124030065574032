.sort-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.sort-panel label {
  margin: 10px;
}

@media screen and (max-width: 900px) {
  .sort-panel h2 {
    flex-basis: 100%;
  }
}
