.btn {
  margin: 0 10px;
  padding: 10px;
  border: none;
  min-width: 100px;
  border-radius: 5px;
  color: #fff;
}

.btn:hover {
  cursor: pointer;
  opacity: 0.7;
}

.submit-btn {
  margin: 18px 0;
}
