.apartments-panel {
  margin-bottom: 100px;
}
.apt-block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 15px 0;
  padding: 10px;
  background-color: white;
}

.apt-info {
  display: inline-block;
  word-break: break-word;
}
.apt-buttons {
  display: flex;
}

@media screen and (max-width: 900px) {
  .apartments-panel .apt-buttons {
    min-height: 150px;
    flex-direction: column;
    justify-content: space-around;
  }
}
