.apartment-form {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  background-color: rgb(219, 219, 219);
  padding: 10px;
}

textarea {
  resize: vertical;
  max-height: 200px;
}

.apartment-form input,
select,
textarea {
  min-height: 30px;
}

.apartment-form label {
  display: block;
}

@media screen and (max-width: 900px) {
  .apartment-form {
    flex-direction: column;
    align-items: stretch;
    padding: 15px;
  }

  .form-field > input,
  .form-field > select,
  .form-field > textarea {
    width: 100%;
  }
}
